<template>
    <div>
        <jy-query ref="form" :model="formInline">
            <jy-query-item label="开始时间:" prop="time" >
                <el-date-picker v-model="formInline.startTime" value-format="yyyy-MM-dd" :picker-options="startTime" type="date" placeholder="选择日期" ></el-date-picker>
            </jy-query-item>
            <jy-query-item label="结束时间:" prop="time" >
                <el-date-picker v-model="formInline.endTime" value-format="yyyy-MM-dd" :picker-options="endTime" type="date" placeholder="选择日期" ></el-date-picker>
            </jy-query-item>
            <jy-query-item prop="routeId" label="线路" >
                <el-select v-model="formInline.routeIds" multiple>
                    <el-option v-for="one in routeOptions" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="vehicleIds" :span="2">
                <el-input v-model="formInline.vehicleNames" placeholder="选择车辆" readonly @focus="chooseCar"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" >查询</el-button>
                <el-button type="primary" @click="resetForm('formInline')" >重置</el-button>
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('formInline')" v-if="btnexist('reset')">重置</el-button>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeNa" label="线路"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号码"></jy-table-column>
            <jy-table-column prop="totalShift" label="计划班次"></jy-table-column>
            <jy-table-column prop="exShift" label="实际趟次"></jy-table-column>
            <jy-table-column prop="lackShift" label="缺班趟次"></jy-table-column>
            <jy-table-column prop="ontimes" label="准点趟次"></jy-table-column>
            <jy-table-column prop="exShiftRate" label="计划完成率"></jy-table-column>
            <jy-table-column prop="lackShiftRate" label="缺班率"></jy-table-column>
            <jy-table-column prop="ontimesRate" label="发车准点率"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
    </div>
</template>
<script>
import {btnMixins} from '@/common/js/button.mixin'
import carSelectbatch from '@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch'
export default {
    data() {
        return {
           formInline: {
                startTime: '',
                endTime: '',
                routeIds: [],
                vehicleIds: [],
                vehicleNames: ''
            },
            dataList: [],
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            routeOptions:[],
            pickerOptions0: {

            },
            startTime: {
                disabledDate: time => {
                    if (this.formInline.endTime) {
                        return (
                            time.getTime() >= new Date(this.formInline.endTime).getTime()
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
            endTime: {
                disabledDate: time => {
                    if (this.formInline.startTime) {
                        return (
                            time.getTime() > Date.now() ||
                            time.getTime() < new Date(this.formInline.startTime).getTime() -8.64e7
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
        }
    },
    async created() {
        await this.getRouteList()
        this.getList()
    },
    mixins:[btnMixins],
    components: {
        carSelectbatch,
    },
    methods: {
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeOptions = detail
            })
        },
        getList() {
            let url = "/stat/route/shift/queryPageVehicleMsg"
            let option = {
                ...this.formInline,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.$http.post(url, option).then(({ detail }) => {
                console.log(detail)
                let list = detail.list.map(item=>{
                    item.totalShift = item.totalShift?item.totalShift+"次":''
                    item.exShift = item.exShift?item.exShift+"次":''
                    item.lackShift = item.lackShift?item.lackShift+"次":''
                    item.ontimes = item.ontimes?item.ontimes+"次":''
                    item.exShiftRate = item.exShiftRate?Math.round(item.exShiftRate*100)+'%':''
                    item.lackShiftRate = item.lackShiftRate?Math.round(item.lackShiftRate*100)+'%':''
                    item.ontimesRate = item.ontimesRate?Math.round(item.ontimesRate*100)+'%':''
                    return {
                        ...item
                    }
                })
                this.dataList = list
                this.total = detail.total
            });
        },
        // 查询表格
        oncheck() {
            this.getList()
        },
        // 重置
        resetForm() {
            this.formInline.routeIds = []
            this.formInline.endTime = ''
            this.formInline.startTime = ''
            this.formInline.vehicleIds = []
            this.formInline.vehicleNames = ''
            this.pageIndex = 1
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getList()
        },
        chooseCar(){
            this.$refs.carSelectbatch.init(this.carList)
        },
        chooseCarList(list){
            this.carList = list
            this.formInline.vehicleNames = list.map(item=>item.plateNum).join(',')
            this.formInline.vehicleIds = list.map(item=>item.vehicleId)
        },
    }
}

</script>
<style  scoped>
    .total {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 54px;
        background: inherit;
        background-color: rgba(215, 215, 215, 1);
        padding: 20px;
        font-size: initial;
    }
</style>